@import '../../shared/settings';

.position-indicator {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 99;
    margin: 0;
    width: 14px;
    height: 14px;

    &::before,
    &::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    &::before {
        position: absolute;
        top: -8px;
        left: -8px;
        animation: map-pin-animation ease-out 2.5s infinite forwards;
        opacity: 0;
        border-radius: 18px;
        box-shadow: inset 0 0 6px $brand;
        background-color: rgba($brand, .2);
        width: 34px;
        height: 34px;
        content: ' ';
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        border: 5px solid $brand;
        border-radius: 9px;
        background: white;
        width: 18px;
        height: 18px;
        content: ' ';
    }
}

@keyframes map-pin-animation {
    0% {
        transform: scale(.4);
        opacity: 1;
    }

    10% {
        opacity: 1;
    }

    90%,
    to {
        transform: scale(1.6);
        opacity: 0;
    }
}
