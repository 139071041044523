@import '../../shared/settings';

.carousel-left,
.carousel-right {
    display: block;
    position: absolute;
    border: 1px solid rgba($light-grey, 0.5);
    border-radius: 12px;
    background-color: white;
    padding: 0;
    width: 24px;
    height: 24px;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 8px;
        fill: $dark-grey;
    }

    &:hover {
        border: 1px solid $light-grey;
        background-color: white;
    }
}

.carousel-left {
    bottom: 0;
    left: 0;

    svg {
        transform: translate(-50%, -50%) rotate(0.5turn);
    }
}

.carousel-right {
    right: 0;
    bottom: 0;

    svg {
        transform: translate(-50%, -50%);
    }
}

.keen-slider__slide {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat no-repeat;
    background-color: white;
    min-width: calc(100% + 0px);
    max-width: calc(100% + 0px);

    img {
        object-fit: contain;
    }

    &.cover {
        background-size: cover;
        img {
            object-fit: cover;
        }
    }

    button,
    img {
        width: 100%;
        height: 100%;
    }
}

.carousel-container {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    user-select: none;

    .carousel-top {
        position: relative;
        flex-grow: 100;
        line-height: 0;
        font-size: 0;

        .carousel-left,
        .carousel-right {
            top: 50%;
            transform: translateY(-50%);
        }

        .carousel-left {
            left: 14px;
        }

        .carousel-right {
            right: 14px;
        }

        @include respond-to(mobile) {
            .carousel-left {
                left: 20px;
            }

            .carousel-right {
                right: 20px;
            }
        }
    }
}

.carousel-no-slider {
    height: 100%;
}

.carousel-indicator {
    display: flex;
    position: absolute;
    right: 14px;
    bottom: 32px;
    flex-direction: row;
    margin: 0;
    list-style: none;
    text-align: center;

    span {
        display: block;
        margin-left: 6px;
        border-radius: 4px;
        background-color: $light-grey;
        cursor: pointer;
        padding: 0;
        width: 8px;
        height: 8px;
        transition-duration: $fast;
        transition-property: background-color;

        &:hover {
            background-color: $grey;
        }

        &.active {
            background-color: $brand;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    @include respond-to(mobile) {
        right: 20px;
        bottom: 22px;
    }
}

.modal-image {
    .carousel-thumbnails {
        margin: 20px 0;
    }
}

.carousel-counter {
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: $grey;
    font-size: 13px;
    user-select: none;
}

.carousel-thumbnails {
    margin-top: var(--gutter-size);
    position: relative;

    .carousel-thumbnails-holder {
        width: ((78px + 8px) * 3) - 8px;
    }

    .carousel-navigation {
        width: ((78px + 8px) * 3) - 8px + 88px;
    }

    &[data-length='2'],
    &[data-length='3'] {
        .carousel-thumbnails-holder {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &[data-length='2'] {
        .carousel-thumbnails-holder {
            width: ((78px + 8px) * 2) - 8px;
        }
        .carousel-navigation {
            width: ((78px + 8px) * 2) - 8px + 88px;
        }
    }

    .carousel-thumbnails-holder {
        margin: 0 auto;
        height: 78px;

        button {
            border: 1px solid $light-grey;
            background-color: white;
            border-radius: 8px;

            width: 78px;
            height: 78px;

            &:hover,
            &.active {
                border-color: $grey;
            }

            &.cover {
                border: none;
            }
        }
    }

    .carousel-navigation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 22px;
        pointer-events: none;

        button {
            pointer-events: all;
        }
    }
}
