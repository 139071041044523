@import '../../shared/settings';

.grid-list,
.grid-block {
    > .searchbox,
    > .google-places {
        margin-bottom: var(--gutter-size);
    }
}

.grid-list {
    display: flex;
    flex-direction: column;

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: var(--gutter-size);
        margin: 0;
        padding: 0;
    }

    li {
        border-top: 1px solid var(--border);
        padding-top: var(--gutter-size);
        display: flex;
        flex-direction: column;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            border-top-width: 0;
            padding-top: 0;
        }

        figure {
            height: 220px;
            img {
                transition: transform 300ms;
            }
            .badges {
                position: absolute;
                left: 16px;
                bottom: 16px;
            }
        }

        > a {
            color: var(--dark-grey);
            display: flex;
            flex-direction: column;
            text-decoration: none;
            flex-grow: 1;

            &:hover {
                h2 {
                    color: var(--brand);
                }
                figure img {
                    transform: scale(1.025);
                }
            }
        }

        .titles {
            margin: var(--gutter-size) 0;

            strong {
                display: block;
                margin-top: 4px;
            }
        }

        h2,
        h3 {
            margin: 0;
        }

        h2 {
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            display: block;
            transition: color 300ms;
        }

        h3,
        strong {
            line-height: 22px;
            font-weight: normal;
            font-size: 14px;
        }

        h3 {
            display: inline-block;
            margin-right: 8px;
        }

        .price-and-unit {
            text-align: right;
        }
        .price {
            line-height: 24px;
            font-weight: bold;
        }
        .price-striked {
            font-size: 14px;
            font-weight: normal;
        }
        .product-location-unit {
            display: block;
            font-size: 14px;
        }
    }

    @include respond-to-max(1000px) {
        ul {
            grid-template-columns: 1fr 1fr;
        }

        li {
            &:nth-child(3) {
                border-top-width: 1px;
                padding-top: var(--gutter-size);
            }
        }
    }

    @include respond-to(mobile) {
        ul {
            grid-template-columns: 1fr 1fr 1fr;
        }

        li {
            &:nth-child(3) {
                border-top-width: 0px;
                padding-top: 0;
            }
        }
    }

    @include respond-to-max(540px) {
        ul {
            grid-template-columns: 1fr 1fr;
        }

        li {
            &:nth-child(3) {
                border-top-width: 1px;
                padding-top: var(--gutter-size);
            }
        }
    }

    .grid-list-footer {
        border-top: 1px solid var(--border);
        padding: var(--gutter-size);
        margin: var(--gutter-size) -16px -16px;
    }
}

.grid-list-full-width {
    ul {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    li {
        &:nth-child(4) {
            border-top-width: 0;
            padding-top: 0;
        }
    }

    @include respond-to-max(1000px) {
        ul {
            grid-template-columns: 1fr 1fr 1fr;
        }
        li {
            &:nth-child(3) {
                border-top-width: 0;
                padding-top: 0;
            }
            &:nth-child(4) {
                border-top-width: 1px;
                padding-top: var(--gutter-size);
            }
        }
    }

    @include respond-to(mobile) {
        ul {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @include respond-to-max(540px) {
        ul {
            grid-template-columns: 1fr 1fr;
        }
        li {
            &:nth-child(3) {
                border-top-width: 1px;
                padding-top: var(--gutter-size);
            }
        }
    }
}

.grid-intro {
    h1 {
        font-size: 18px;
        margin: 0;
        & + p {
            margin-top: 3px;
        }
    }
    
    margin-bottom: var(--gutter-size);

    p {
        font-size: 14px;
        line-height: 21px;
    }
}

.grid-list-products {
    li {
        .titles {
            min-height: 97px;
            flex-grow: 1;
        }
    }
}

.grid-list-locations {
    li {
        font-size: 14px;

        figure {
            height: 168px;
            border-radius: 8px;

            // overflow-hidden border radius Safari fix
            mask-image: radial-gradient(black, black);

            img {
                object-fit: cover;
            }

            .badges {
                left: auto;
                right: 16px;
            }
        }

        .titles {
            flex-grow: 1;
        }

        .address {
            margin-top: 0;
        }

        .link {
            display: inline-block;
        }
    }
}

.grid-list-no-results {
    display: flex;
    align-items: top;
    justify-content: center;
    height: 100%;
    min-height: 40vh;
    text-align: center;

    .message {
        h3 {
            margin: 48px 0 0;
            line-height: 28px;
            color: $grey;
            font-size: 18px;
            font-weight: normal;

            strong {
                display: block;
                color: $dark-grey;
                font-weight: normal;
            }
        }
    }

    @include respond-to(mobile) {
        border: 0;
        border-top: 1px solid rgba($light-grey, 0.5);
        min-height: 50vh;

        .message {
            h3 {
                font-size: 16px;
            }
        }
    }
}

.grid-list-sorters {
    margin-left: auto;
    display: flex;

    .btn + .btn {
        margin-left: 10px;
    }
}

.grid-list-pagination {
    display: flex;
    flex-direction: row;
    line-height: 32px;
    font-size: 14px;
    font-weight: var(--medium);

    strong {
        color: var(--dark-grey);
    }

    & + ul {
        margin-top: 16px;
    }
}

.btn-full-width {
    display: flex;
    align-items: center;
    justify-content: center;
}
