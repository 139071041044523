@mixin loading($foreground-color: $dark-grey, $background-color: $light-grey, $size: 0, $border-size: 3px) {
    display: inline-block;
    position: relative;
    transform: translateZ(0);
    animation: load .6s infinite linear;
    border-top: $border-size solid rgba($background-color, .2);
    border-right: $border-size solid rgba($background-color, .2);
    border-bottom: $border-size solid rgba($background-color, .2);
    border-left: $border-size solid rgba($foreground-color, 1);

    &,
    &::after {
        border-radius: 50%;
    }

    @if $size != 0 {
        &,
        &::after {
            width: $size;
            height: $size;
        }
    }
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
