.filter-sheet {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.filter-sheet-content {
    flex-grow: 1;
    overflow: auto;
}

.filter-sheet-header {
    border-bottom: 1px solid  var(--sys-color-light-outline-variant);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 16px;
    height: 60px;
    box-sizing: border-box;


    h4 {
        padding: 0;
        margin: 0;
    }
}

.filter-sheet-footer {
    padding: 16px;
    border-top: 1px solid  var(--sys-color-light-outline-variant);

    .btn {
        width: 100%;
    }
}

.filter-sheet-content .filter-box > * {
    padding-left: 16px;
    padding-right: 16px;
}