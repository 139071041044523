@import '../../shared/settings';

@keyframes pulse {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.btn-user-location {
    background: var(--success);
    padding: 0;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: 1px solid var(--border);

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transition: color 300ms;
        width: 22px;
        height: auto;
        fill: white;
    }

    &.locating {
        svg {
            animation: pulse 0.8s linear infinite;
        }
    }

    &:hover {
        background: var(--success-darkened);
    }
}
