.location-features {
    display: flex;
    flex-direction: row;

    > span {
        margin-right: 3px;
        &:last-child {
            margin-right: 0;
        }
    }
}
