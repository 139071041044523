@import '../../shared/settings';

.btn-switch {
    display: inline-block;
    position: relative;
    background: none;

    padding: 0;
    min-width: 50px;
    height: 32px;

    input[type] {
        opacity: 0;
        margin: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    label {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        display: block;
        transition: all 0.15s ease;
        margin: 0;
        border-radius: 24px;
        background: $light-grey;
        width: 100%;
        height: 100%;

        div {
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: left $fast;
            border-radius: 24px;
            background: white;
            width: 24px;
            height: 24px;

            &::after {
                position: absolute;
                top: 8px;
                left: 8px;
                transition: opacity $fast;
                border-radius: 8px;
                background: $light;
                width: 8px;
                height: 8px;
                content: ' ';
            }
        }
    }

    span {
        display: inline-block;
        position: relative;
        padding: 0 8px 0 32px;
        line-height: 27px;
        text-transform: uppercase;
        font-size: 12px;
        color: var(--text-light);
    }

    &:hover {
        label {
            div {
                &::after {
                    background: var(--success);
                }
            }
        }

        input {
            &:checked ~ label {
                div {
                    &::after {
                        background: $light-grey;
                    }
                }
            }
        }
    }

    input {
        &:checked ~ label {
            background: var(--success);
            div {
                left: calc(100% - 24px - 4px);
                &::after {
                    background: var(--success);
                }
            }
        }

        &:checked ~ span {
            color: white;
            padding: 0 32px 0 8px;
        }

        &:disabled ~ label {
            opacity: 0.5;
        }
    }
}
