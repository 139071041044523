@import '../../shared/settings';

.grid-breadcrumbs-back {
    color: var(--dark-grey);

    svg {
        transform: rotate(0.5turn);
        margin-right: 12px;
    }

    @include respond-to(mobile) {
        margin-bottom: 10px;
        display: inline-block;

        svg {
            margin-right: 6px;
        }
    }
}

.grid-breadcrumbs {
    display: flex;

    a {
        white-space: nowrap;
        color: var(--dark-grey);

        &:hover {
            text-decoration: underline;
        }

        &.grid-breadcrumbs-more {
            margin-left: auto;
        }
    }
}

.grid-breadcrumbs-path {
    margin-left: 5px;

    &:first-child {
        margin-left: 0;
    }

    a {
        display: inline-block;
        text-decoration: underline;
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            text-decoration: none;
        }
    }
}
