@import '../../shared/settings';

.pagination-limit,
.pagination-ordering {
    label {
        margin-right: 10px;
    }
}

.sorter-link {
    position: relative;
    margin-left: 12px;
    padding-right: 14px;
    color: $brand;
    font-weight: var(--medium);

    svg {
        position: absolute;
        top: 50%;
        right: 0;
        width: 7px;
        fill: $light-grey;

        &.up {
            transform: rotate(0.5turn) translateY(5px);
        }

        &.down {
            transform: translateY(2px);
        }
    }

    &.up {
        color: $brand;

        .up {
            fill: $brand;
        }
    }

    &.down {
        color: $brand;

        .down {
            fill: $brand;
        }
    }
}
