@import '../../shared/settings';

.google-maps {
    background: var(--background);
    overflow: hidden;
    height: 100%;

    div {
        outline: none;
    }
}
