@import '../../shared/settings';

input {
    &[type='checkbox'],
    &[type='radio'] {
        position: relative;
        top: -2px;
        margin-right: 10px;
        font-size: 42px;
    }
}

span.checkbox,
span.radio {
    display: inline-block;
    position: relative;
    height: 16px;
    cursor: pointer;

    input {
        &[type='checkbox'],
        &[type='radio'] {

            cursor: pointer;

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            opacity: 0;
            margin: 0;
            width: 16px;
            height: 16px;

            + .outline {
                display: block;
                position: relative;
                transition: border var(--fast), background var(--fast);
                border: 1px solid var(--border);
                background-color: white;
                cursor: pointer;
                width: 14px;
                height: 14px;
                pointer-events: none;
            }
        }
    }
}

span.radio {
    input {
        & + span {
            border-radius: 50%;

            .fill {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: scale(2.5) translate(-50%, -50%);
                transition: transform $slow, opacity $fast;
                opacity: 0;
                border-radius: 50%;
                background-color: white;
                width: 4px;
                height: 4px;
                transform-origin: 0 0;
            }
        }

        &:checked,
        &:focus {
            & + .outline {
                border-color: $brand;
                background-color: $brand;
            }
        }

        &:checked + .outline .fill {
            transform: scale(1) translate(-50%, -50%);
            opacity: 1;
        }
    }
}

span.checkbox {
    height: 16px;

    input {
        & + .outline {
            border-radius: 3px;
            text-align: center;
            line-height: 14px;

            svg {
                fill: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: opacity $fast;
                margin: 0;
                width: 9px;

                &.checkmark {
                    opacity: 0;
                    height: 8px;
                }
            }
        }

        &:indeterminate,
        &:checked,
        &:focus {
            & + .outline {
                border-color: var(--success);
                background-color: var(--success);

                svg {
                    opacity: 1;
                }
            }
        }
    }
}

.checkbox-disabled,
.radio-disabled {
    opacity: 0.5;
}

span.loading-checkbox {
    border-top: 3px solid rgba($light-grey, 0.5);
    border-right: 3px solid rgba($light-grey, 0.5);
    border-bottom: 3px solid rgba($light-grey, 0.5);
    border-left: 3px solid $brand;

    &,
    &::after {
        border-radius: 50%;
        width: 10px;
        height: 10px;
    }
}
