$mobile: 768px;
$tablet: 1024px;
$desktop: 1236px;

@mixin respond-to-min($px) {
    @media only screen and (min-width: $px) {
        @content;
    }
}

@mixin respond-to-max($px) {
    @media only screen and (max-width: $px) {
        @content;
    }
}

@mixin respond-to-min-max($min, $max) {
    @media only screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: $mobile) {
            @content;
        }
    }

    @if $media == not-mobile {
        @media only screen and (min-width: $mobile) {
            @content;
        }
    }

    @if $media == mobile-portrait {
        @media only screen and (max-width: $mobile) and (orientation: portrait) {
            @content;
        }
    }

    @if $media == tablet {
        @media only screen and (min-width: $mobile) and (max-width: $tablet) {
            @content;
        }
    }

    @if $media == not-desktop {
        @media only screen and (max-width: $tablet) {
            @content;
        }
    }

    @if $media == desktop {
        @media only screen and (min-width: $tablet) {
            @content;
        }
    }

    @if $media == not-wide {
        @media only screen and (max-width: $desktop) {
            @content;
        }
    }
}
