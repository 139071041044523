@mixin scrollbar {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
}

@mixin styledScrollbar(
    $size: 5px,
    $foreground-color: $grey,
    $background-color: mix($foreground-color, white, 50%)
) {
    @include scrollbar;

    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $size;
    }

    &::-webkit-scrollbar-track {
        background: $light;
        border-radius: $size;
    }
}
