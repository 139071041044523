%inlinebutton {
    display: inline-block;
    border-radius: 0;
    padding: 0;
    height: auto;
    vertical-align: baseline;
    text-align: left;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    &,
    &:hover {
        background: transparent !important;
    }

    &.underline {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
}

.backoffice-container {
    button.inline {
        color: var(--brand);
    }
}