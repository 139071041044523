@import '../../shared/settings';

.google-places {
    .google-places-holder {
        position: relative;
        display: flex;
        gap: var(--gutter-size);

        > div:first-child {
            flex-grow: 1;
        }
    }

    input[type='text'] {
        position: relative;
        padding: 10px 10px 10px 50px;
        text-indent: 0;
        color: var(--dark-grey);
        font-size: 16px;
        height: 40px;

        &:focus {
            border: 1px solid var(--dark-grey);
        }

        &::placeholder {
            color: var(--text-light);
        }
    }

    .magnifier {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        transition: color $fast;
        width: 18px;
        height: 18px;
        fill: var(--brand);

        &.is-searching,
        &.has-result {
            fill: var(--brand);
        }
    }
}

.pac-container {
    transform: translate(1px, 10px);
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(black, 0.15);

    .pac-item {
        line-height: 40px;
        color: $grey;
        border-color: $lighter;

        &:first-child {
            border-top: 0 !important;
        }

        &:first-child {
            border-top: 0 !important;
        }

        &:hover {
            background-color: rgba($light, 0.35);

            .pac-item-query {
                color: var(--brand);
            }
        }
    }

    .pac-icon {
        margin-top: 11px;
        margin-left: 9px;
    }

    .pac-item-query {
        color: $dark-grey;
    }

    &::after {
        text-align: center;
        padding: 20px;
        background-position: center;
        border-top: 1px solid $lighter;
    }

    @include respond-to(mobile) {
        width: calc(100% - 44px) !important;
        box-sizing: content-box;
    }
}

::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
