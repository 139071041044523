@import '../../shared/settings';

.search-filters {
    line-height: 20px;
    font-size: 14px;

    .filters {
        padding: 0;
    }

    .pagination-ordering {
        display: flex;
        align-items: center;
        label {
            font-size: 16px;
            font-weight: bold;
        }
        select {
            margin-left: auto;
        }
    }
}

.filter-box {
    padding: 14px 0;
    border-bottom: 1px solid var(--sys-color-light-outline-variant);
    font-size: 14px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &:last-child {
        border-bottom: none;
    }

    a {
        color: var(--text);
    }

    small {
        font-size: inherit;
    }

    .grid-block & {
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    .color-filter,
    .multiselect-filter,
    .chosen-filter {
        @include styledScrollbar(5px, rgba($light-grey, 0.5));
        overflow: hidden;
        overflow-y: auto;
        max-height: 204px;
        margin: 0;
    }

    .color-filter {
        a {
            display: flex;
            align-items: center;

            .color,
            .multicolor {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.filter-box-header {
    display: flex;
    align-items: center;
    position: relative;

    h4,
    button:first-child,
    label {
        color: var(--dark-grey);
        font-size: 16px;
        font-weight: bold;
        margin: 0 auto 0 0;
        line-height: 36px;
    }

    a {
        color: var(--brand);
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    button:first-child {
        position: relative;
        border-radius: 0;
        background: none;
        padding: 0;
        width: 100%;
        height: auto;
        text-align: left;
        display: flex;
        align-items: center;

        svg {
            margin-left: auto;
            transform: rotate(180deg);
            width: 7px;
            height: 5px;
            fill: var(--dark-grey);
            margin-right: 10px;
        }
    }
}

.filter-box .filter-back {
    color: var(--brand);
    display: flex;
    gap: 8px;
    align-items: center;

    svg {
        fill: var(--brand);
        transform: rotate(180deg);
    }
}

.filter-box-collapsed .filter-box-header {
    button:first-child {
        svg {
            transform: rotate(0deg);
        }
    }
}

.multiselect-filter {
    ul {
        list-style: none;
        margin: 0;
        padding: 0 0 4px 0;
        display: flex;
        gap: 10px;
        flex-direction: column;

        li:not(a), a {
            display: flex;
            gap: 8px;
            line-height: 16px;
            align-items: center;
        }
    }
}
